<template>  
  <div class="protocolContainer">
      <!-- <div class="title">
          {{protocolDetail.title}}
      </div>     -->
    <div class="content" v-html="protocolDetail.content">

    </div>     
  </div>
</template>
<script>

export default {
  name: "helpDetail",
  components: {},
  data() {
    return {
        protocolDetail:{}
    };
  },

  mounted() {

  },

  methods: {
      getDatas(id){
        protocolFwd.param_getContentDetails.param = {
            id: id,
        }
      http.getRes(protocolFwd.param_getContentDetails).then(response => {
        const { code, message, value } = response.data
        if (Number(code)===0) {
           this.protocolDetail = value
        } else {
          this.$EL_MESSAGE(message)
        }
      })
      }
  }
};
</script>

<style>

</style>
