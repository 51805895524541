<template>
  <div class="pageContent">
    <searchHeader />
    <div class="matching">
      <el-row :gutter="2">
        <el-col :span="4">
          <el-col :span="24">
            <div class="helpMatchingLeft">
              帮助中心
            </div>
            <div class="matchingLeft">
              <div>
                <p class="matchingLeftTitle" @click="changeRuleDown">平台规则
                   <span class="downArrow" v-show="!isRuleDown">
                    <i class="el-icon-arrow-down"></i>
                  </span>
                  <span class="downArrow" v-show="isRuleDown">
                    <i class="el-icon-arrow-up"></i>
                  </span>
                </p>
              </div>
              <div v-show="isRuleDown">
                <div
                  v-for="item of newsDatas"
                  :key="item.id"
                  @click="recordData(item, 1)"
                
                >
                    <p
                      class="matchingLeftItem"
                      :class="item.id == currentId ? 'activeItem' : ''"
                    >
                      {{ item.title }}
                    </p>
                </div>
              </div>         
              <div>
                <p class="matchingLeftTitle" @click="changeDevierDown">交收服务
                  <span class="downArrow" v-show="!isDevierDown">
                    <i class="el-icon-arrow-down"></i>
                  </span>
                  <span class="downArrow" v-show="isDevierDown">
                    <i class="el-icon-arrow-up"></i>
                  </span>
                </p>
              </div>
              <div v-show="isDevierDown">
                <div
                  v-for="item of deleDatas"
                  :key="item.id"
                  @click="recordData(item, 2)"
                >
                    <p
                      class="matchingLeftItem"
                      :class="item.id == currentId ? 'activeItem' : ''"
                    >
                      {{ item.title }}
                    </p>
                </div>
              </div>
              <div>
                <p class="matchingLeftTitle" @click="changeZjDown">合作质检机构
                   <span class="downArrow" v-show="!isZjDown">
                    <i class="el-icon-arrow-down"></i>
                  </span>
                  <span class="downArrow" v-show="isZjDown">
                    <i class="el-icon-arrow-up"></i>
                  </span>
                </p>
              </div>
              <div v-show="isZjDown">
                 <div
                  v-for="item of cooperationDatas"
                  :key="item.id"
                  @click="recordData(item, 4)"
                >
                    <p
                      class="matchingLeftItem"
                      :class="item.id == currentId ? 'activeItem' : ''"
                    >
                      {{ item.title }}
                    </p>
                </div>
              </div>
              <div>
                <p class="matchingLeftTitle" @click="changeUserDown">常见问题指引
                  <span class="downArrow" v-show="!isUserDown">
                    <i class="el-icon-arrow-down"></i>
                  </span>
                  <span class="downArrow" v-show="isUserDown">
                    <i class="el-icon-arrow-up"></i>
                  </span>
                </p>
              </div>
              <div v-show="isUserDown">
                 <div
                  v-for="item of userHelpDatas"
                  :key="item.id"
                  @click="recordData(item, 3)"
                >
                    <p
                      class="matchingLeftItem"
                      :class="item.id == currentId ? 'activeItem' : ''"
                    >
                      {{ item.title }}
                    </p>
                </div>
              </div>
              <div>
                <p class="matchingLeftTitle" @click="changeConactDown">联系我们
                  <span class="downArrow" v-show="!isConactDown">
                    <i class="el-icon-arrow-down"></i>
                  </span>
                  <span class="downArrow" v-show="isConactDown">
                    <i class="el-icon-arrow-up"></i>
                  </span>
                </p>
              </div>
              <div v-show="isConactDown">
                 <div
                  v-for="item of contactUsData"
                  :key="item.id"
                  @click="recordData(item, 9)"
                >
                    <p
                      class="matchingLeftItem"
                      :class="item.id == currentId ? 'activeItem' : ''"
                    >
                      {{ item.title }}
                    </p>
                </div>
              </div>
              <div>
                <p class="matchingLeftTitle">下载中心</p>
              </div>
              <!-- <router-link to="/helpCenter"> -->
               <div
                v-for="item of uploadFileDatas"
                :key="item.id"
                @click="recordData(item, 8)"
              >
                  <p
                    class="matchingLeftItems"
                  >
                   <a :href="item.source" target="_blank">{{ item.title }}</a>
                  </p>
              </div>
            </div>
          </el-col>
        </el-col>
        <el-col :span="20">
          <div class="matchingRight">
            <helpDetail ref="helpId" />
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import searchHeader from "../base/header/searchHeader";
import helpDetail from "./subpage/helpDetails";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      newsDatas: [],
      deleDatas: [],
      userHelpDatas: [],
      uploadFileDatas:[],
      cooperationDatas: [],
      posNewData:[],
      posDeleDatas:[],
      posUserHelpDatas:[],
      posCoperationDatasa:[],
      contactUsData: [],
      posContactUsDataDatas: [],
      currentId: this.$route.params.id,
      isRuleDown:true,
      isDevierDown:false,
      isZjDown:false,
      isUserDown: false,
      isConactDown: false
    };
  },
  computed: {
    ...mapGetters(["selectHelpNameGetter"]),
  },
  watch: {
    currentId(val) {
      if (this.selectHelpNameGetter == window.c.downloadFile){
       this.$EL_MESSAGE('请直接点击要下载的文件');
      }else{
        this.$refs.helpId.getDatas(val);
        if(this.posNewData.includes(val)){
          this.isRuleDown = false
          this.changeRuleDown()
        }else if(this.posDeleDatas.includes(val)){
          this.isDevierDown = false
          this.changeDevierDown()
        }else if(this.posUserHelpDatas.includes(val)){
          this.isUserDown = false
          this.changeUserDown()
        }else if(this.posCoperationDatasa.includes(val)){
          this.isZjDown = false
          this.changeZjDown()
        }
      }
    },
    selectHelpNameGetter(val) {
      let that = this;
      if (val == window.c.plateRules) {
        that.currentId = that.newsDatas[0].id;
      } else if (val == window.c.delevService) {
        that.currentId = that.deleDatas[0].id;
      } else if (val == window.c.userHelp) {
        that.currentId = that.userHelpDatas[0].id;
      } else if (val == window.c.cooperation) {
        that.currentId = that.cooperationDatas[0].id;
      } else if (val == window.c.contactUs) {
        that.currentId = that.contactUsData[0].id;
      } 
     else {
        that.currentId = that.newsDatas[0].id;
      }
      //  else if (val == window.c.downloadFile) {
      //   that.currentId = that.uploadFileDatas[0].id;
      // } 
    },
  },
  components: {
    searchHeader,
    helpDetail,
  },
  methods: {
    recordData(item, index) {
      this.currentId = item.id;
      if (index == 1) {
        this.$store.commit("setSelectHelpName", window.c.plateRules);
      } else if (index == 2) {
        this.$store.commit("setSelectHelpName", window.c.delevService);
      } else if (index == 3) {
        this.$store.commit("setSelectHelpName", window.c.userHelp);
      } else if (index == 4) {
        this.$store.commit("setSelectHelpName", window.c.cooperation);
      }else if (index == 8) {
        this.$store.commit("setSelectHelpName", window.c.downloadFile);
      }else if (index == 9) {
        this.$store.commit("setSelectHelpName", window.c.contactUs);
      }
      this.$router.push({
        name:'helpCenter',
        params:{
          id: item.id
        }
      })
    },
    getList() {
      // 平台规则
      protocolFwd.param_getContentTitleList.param.page = 0;
      protocolFwd.param_getContentTitleList.param.size = 30;
      protocolFwd.param_getContentTitleList.param.columnId =
        window.c.plateRules;
      http.getRes(protocolFwd.param_getContentTitleList).then((response) => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.posNewData = []
          this.newsDatas = value.content;
          value.content.forEach(element => {
            this.posNewData.push(element.id)
          });
          if(this.$route.params.id || (this.selectHelpNameGetter &&
            this.selectHelpNameGetter != window.c.plateRules)){

          }else {
            this.currentId = value.content[0].id;
            this.$refs.helpId.getDatas(value.content[0].id);
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
      // 交收服务
      protocolFwd.param_getContentTitleList.param = {
        columnId: window.c.delevService,
        page: 0,
        size: 10,
      };
      http.getRes(protocolFwd.param_getContentTitleList).then((response) => {
        const { code, message, value } = response.data;
        if (code == 0) {
          if (this.selectHelpNameGetter == window.c.delevService&&!this.$route.params.id) {
            this.currentId = value.content[0].id;
            this.$refs.helpId.getDatas(value.content[0].id);
          }
          this.deleDatas = value.content;
          this.posDeleDatas = []
          value.content.forEach(element => {
            this.posDeleDatas.push(element.id)
          });
        } else {
          this.$EL_MESSAGE(message);
        }
      });
      // 用户帮助
      protocolFwd.param_getContentTitleList.param = {
        columnId: window.c.userHelp,
        page: 0,
        size: 10,
      };
      http.getRes(protocolFwd.param_getContentTitleList).then((response) => {
        const { code, message, value } = response.data;
        if (code == 0) {
          if (this.selectHelpNameGetter == window.c.userHelp&&!this.$route.params.id) {
            this.currentId = value.content[0].id;
            this.$refs.helpId.getDatas(value.content[0].id);
          }
          this.userHelpDatas = value.content;
          this.posUserHelpDatas = []
          value.content.forEach(element => {
            this.posUserHelpDatas.push(element.id)
          });
        } else {
          this.$EL_MESSAGE(message);
        }
      });
      // 联系我们
      protocolFwd.param_getContentTitleList.param = {
        columnId: window.c.contactUs,
        page: 0,
        size: 10,
      };
      http.getRes(protocolFwd.param_getContentTitleList).then((response) => {
        const { code, message, value } = response.data;
        if (code == 0) {
          if (this.selectHelpNameGetter == window.c.contactUs&&!this.$route.params.id) {
            this.currentId = value.content[0].id;
            this.$refs.helpId.getDatas(value.content[0].id);
          }
          this.contactUsData = value.content;
          this.posContactUsDataDatas = []
          value.content.forEach(element => {
            this.posContactUsDataDatas.push(element.id)
          });
        } else {
          this.$EL_MESSAGE(message);
        }
      });
      // 下载中心
      protocolFwd.param_getContentTitleList.param = {
        columnId: window.c.downloadFile,
        page: 0,
        size: 10,
      };
      http.getRes(protocolFwd.param_getContentTitleList).then((response) => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.uploadFileDatas = value.content;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
      // 合作质检机构
      protocolFwd.param_getContentTitleList.param = {
        columnId: window.c.cooperation,
        page: 0,
        size: 10,
      };
      http.getRes(protocolFwd.param_getContentTitleList).then((response) => {
        const { code, message, value } = response.data;
        if (code == 0) {
          if (this.selectHelpNameGetter == window.c.cooperation&&!this.$route.params.id) {
            this.currentId = value.content[0].id;
            this.$refs.helpId.getDatas(value.content[0].id);
          }
          this.cooperationDatas = value.content;
          this.posCoperationDatasa = []
          value.content.forEach(element => {
            this.posCoperationDatasa.push(element.id)
          });
        } else {
          this.$EL_MESSAGE(message);
        }
      });
      if(this.$route.params.id){
        this.$refs.helpId.getDatas(this.$route.params.id);
        let val = Number(this.$route.params.id)
        let _this = this
        setTimeout(function(){
        if(_this.posNewData.includes(val)){
          _this.isRuleDown = false
          _this.changeRuleDown()
        }else if(_this.posDeleDatas.includes(val)){
          _this.isDevierDown = false
          _this.changeDevierDown()
        }else if(_this.posUserHelpDatas.includes(val)){
          _this.isUserDown = false
          _this.changeUserDown()
        }else if(_this.posContactUsDataDatas.includes(val)){
          _this.isConactDown = false
          _this.changeConactDown()
        }else if(_this.posCoperationDatasa.includes(val)){
          _this.isZjDown = false
          _this.changeZjDown()
        }
        },1500)
        
      }
    },
    changeRuleDown() {
      if (this.isRuleDown) {
        this.isRuleDown = false;
      } else {
        this.isRuleDown = true;
        this.isDevierDown = false
        this.isZjDown = false
        this.isUserDown = false
        this.isConactDown = false;
      }
    },
    changeConactDown() {
      if (this.isConactDown) {
        this.isConactDown = false;
      } else {
        this.isConactDown = true;
        this.isDevierDown = false
        this.isZjDown = false
        this.isUserDown = false
        this.isRuleDown = false
      }
    },
    changeUserDown() {
      if (this.isUserDown) {
        this.isUserDown = false;
      } else {
        this.isUserDown = true;
        this.isDevierDown = false
        this.isZjDown = false
        this.isRuleDown = false
        this.isConactDown = false;
      }
    },
    changeZjDown() {
      if (this.isZjDown) {
        this.isZjDown = false;
      } else {
        this.isZjDown = true;
        this.isDevierDown = false
        this.isRuleDown = false
        this.isUserDown = false
        this.isConactDown = false;
      }
    },
    changeDevierDown(){
      if (this.isDevierDown) {
        this.isDevierDown = false;
      } else {
        this.isDevierDown = true;
        this.isRuleDown = false
        this.isZjDown = false
        this.isUserDown = false
        this.isConactDown = false;
      }
    }
  },
  mounted() {
    this.getList();
  },
};
</script>
<style lang="scss" scoped>
.matching {
  width: 1200px;
  // width: 80%;
  // min-width: 1300px;
  min-height: 1000px;
  margin: 10px auto;
  // background-color: #ffffff;
  // border: 1px solid #dcdcdc;
  box-sizing: border-box;
}
.helpMatchingLeft{
  line-height: 50px;
  text-indent:20px;
  font-size:18px;
  color:#fff;
  background-color:#cf4640;
}
.matching .matchingLeft {
  line-height: 40px;
  background-color: #ffffff;
  // border: 1px solid #dcdcdc;
}
.matching .matchingLeft .mainTitle {
  font-weight: 700;
  color: #ffffff;
  background-color: $commonUserTitleColor;
}
.matching .matchingLeft .mainTitle p {
  font-size: 16px;
}
.matching .matchingLeft .matchingLeftTitle {
  font-size: 14px;
  padding: 0 15px;
  background-color:#ddd;
  color:#333;
  cursor: pointer;
  position: relative;

}
.matching .matchingLeft .matchingLeftItem {
  // padding-left: 30px;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #666;
  text-indent:20px;
  position: relative;
  cursor: pointer;
}
.matching .matchingLeft .matchingLeftTitle .downArrow {
  left: 160px;
  top: 0px;
  position: absolute;
}
.matching .matchingLeft .matchingLeftItem:hover{
  color:#cf4640;
  font-size:15px;
}
.matching .matchingLeft .matchingLeftItems{
   font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #666;
  text-align: left;
  position: relative;
  text-indent:20px;
  cursor: pointer;
}
.matching .matchingLeft .matchingLeftItems a{
  font-size: 14px;
  color: #333333;
}
.matching .matchingLeft .matchingLeftItems a:hover{
  // color:red;
  font-size:15px;
}
// .matchingLeft .matchingLeftItem .hoverClass {
//   display: none;

// }
// .matchingLeft .matchingLeftItem:hover .hoverClass {
//   display: block;
//   position: absolute;
//   white-space: normal;
//   top: -14px;
//   left: 25px;
//   // border:1px solid #ddd;
//   // border-radius:5px;
//   color: #333;
// }
.matching .matchingRight {
  padding: 15px 100px;
  min-height: 1000px;
  background-color: #ffffff;
  max-height: 1200px;
  overflow: auto;
  // border: 1px solid #dcdcdc;
}
.router-link-active p.matchingLeftItem {
  color: $commonThemeColor !important;
}
.activeItem {
  color: $commonThemeColor !important;
}
</style>
